//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-392:_1288,_3496,_2480,_9248,_7189,_4652,_5296,_5876;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-392')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-392', "_1288,_3496,_2480,_9248,_7189,_4652,_5296,_5876");
        }
      }catch (ex) {
        console.error(ex);
      }